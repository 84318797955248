import React from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';

import styles from './Tabs.module.scss';

// TODO: типизировать
function Tabs({ tabs, selectedTab, onSelectTab }) {
  const handleSelectTab = (tabValue) => {
    onSelectTab(tabValue);
  };
  return (
    <div className={styles.tabsContainer}>
      <ul className={styles.tabsList}>
        {tabs.map((tab, index) => (
          <li
            className={cn({ [styles.tab]: true, [styles.active]: selectedTab === tab.value })}
            onClick={() => handleSelectTab(tab.value)}
            // TODO: что можно взять за key?
            // eslint-disable-next-line react/no-array-index-key
            key={`${tab.label}__${index}`}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

Tabs.propTypes = {
  tabs: propTypes.arrayOf(propTypes.shape({ label: propTypes.string, value: propTypes.string }))
    .isRequired,
  selectedTab: propTypes.string.isRequired,
  onSelectTab: propTypes.func.isRequired,
};

export default Tabs;
